var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MoneyStatistics flex-center" }, [
    _c("div", { staticClass: "num-box flex-just-between" }, [
      _c("div", { staticClass: "num-item" }, [
        _c("div", { staticClass: "num" }, [
          _c("span", [_vm._v(_vm._s(_vm.costObj.laborCostSumCount))]),
          _c("span", [_vm._v("万元")]),
        ]),
        _c("span", [_vm._v("上月人力成本")]),
      ]),
      _c("div", { staticClass: "num-item" }, [
        _c("div", { staticClass: "num" }, [
          _c("span", [_vm._v(_vm._s(_vm.costObj.planSumCount))]),
          _c("span", [_vm._v("万元")]),
        ]),
        _c("span", [_vm._v("上月应发总工资")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }